import styled from '@emotion/styled'
import React, { Fragment, useEffect, useState } from 'react'

const DetailsContainer = styled.div`
${(props) => (props.backgroundColor ? `background-color: ${props.backgroundColor};` : '#f6f3ef')};
${(props) => (props.title ? `padding: 20px;` : '')};
padding: 40px;
@media (max-width: 768px) {
  padding: 16px;
  ${(props) => (props.title ? `padding: 40px 20px;` : '')};
}
`
const DetailSectionsContaniner = styled.div`
  height: calc(100% - 200px);
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  align-items: start;
  padding: 24px 0;
  ${(props) => (props.extraPadding ? `padding: ${props.extraPadding};` : ';')};
  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: auto;
    padding-bottom: 0;
  }
`
const DetailSectionsContaninerImages = styled.div`
  height: calc(100% - 200px);
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  align-items: start;
  padding: 24px 0 40px 0;
  ${(props) => (props.extraPadding ? `padding: ${props.extraPadding};` : ';')};
  ${(props) => (!props.titleOn && `padding-bottom: 64px;`)};
  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: auto;
  }
`
const DetailSectionImage = styled.img`
  margin: 0 35%;
  object-fit: contain;
  height: ${(props) => (props.IconSize === 'small' ? '60px' : props.IconSize === 'medium' ? '90px' : props.IconSize === 'large' ? '120px' : '120px')};
  @media (max-width: 768px) {
    margin: 0 auto;
    height: ${(props) => (props.IconSize === 'small' ? '60px' : props.IconSize === 'medium' ? '90px' : props.IconSize === 'large' ? '120px' : '120px')};
  }
`
const DetailSectionImageNoHeight = styled.img`
  margin: 0 35%;
  height: 100%;
  object-fit: contain;
  @media (max-width: 768px) {
    width: 80%;
    margin: 0 auto;
  }
`
const OptionalImage = styled.img`
  width: 180px;
  margin: 0 15%;
  height: 80px;
`
const DetailSectionImageContainer = styled.div`
  width: 100%;
  justify-content: center;
  @media (min-width: 769px) {
    display: flex;
    align-items: flex-end;
    margin: 20px;
  }
`
const DetailSectionHeading = styled.div`
  font-size: ${(props) => (props.IconSize === 'small' ? '11px' : props.IconSize === 'medium' ? '13px' : props.IconSize === 'large' ? '20px' : '13px')};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  opacity: 1;
  letter-spacing: -0.71px;
  text-align: center;
  color: #000000;
  line-height: 1.3;
  @media (min-width: 769px) {
    max-width: 250px;
  }
  @media (max-width: 768px) {
    font-style: bold;
    font-size: 13px;
    padding: 16px 0;
  }
`
const DetailSectionContainer = styled.div`
  width: 25%;
  flex-direction: column;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    padding: 10px;
    width: 50%;
    height: 200px;
  }
`
const SmallSubHeading = styled.div`
  font-size: calc(5px + 1vw);
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
  color: ${(props) => props.color ? props.color : 'rgb(var(--black))'};
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  padding-top: 32px;
  font-family: 'SackersGothicStd-Heavy';
  text-align: center;
  @media (max-width: 1025px) {
    font-size: 12px;
  }
`
const NumberContainer = styled.div`
  background: #F5F3EF;
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: #282828;
  border-radius: 50%;
  margin: 0 auto 5% auto;
`

const DetailSection = ({ data }) => {
  return data.map((section, index) => {

    const titleWords = section.title.split(/\s+/);
    const middleIndex = Math.ceil(titleWords.length / 2);
    const wordsLengthExcludingNumbers = section.title.split(/\s+/).filter(word => isNaN(word)).length

    let firstLine, secondLine;

    if (wordsLengthExcludingNumbers % 2 === 1) {
      firstLine = titleWords[0];
      secondLine = titleWords.slice(1).join(' ');
    } else {
      firstLine = titleWords.slice(0, middleIndex).join(' ');
      secondLine = titleWords.slice(middleIndex).join(' ');
    }

    return (
      <DetailSectionContainer index={index} key={index}>
        {section.stepsOn === true ? (
          <Fragment key={`detail-section-${section.title}`}>
            {
              section.image ? (
                <>
                  <NumberContainer> {section.step} </NumberContainer>
                  <DetailSectionImageContainer IconSize={section.IconSize}>
                    {
                      section.noHeight ? (
                        <DetailSectionImageNoHeight loading="lazy" src={section.image} alt={section.alt} titleText={section.title} />
                      ) : (
                        <DetailSectionImage IconSize={section.IconSize} loading="lazy" src={section.image} alt={section.alt} titleText={section.title} />
                      )
                    }
                  </DetailSectionImageContainer>
                  <DetailSectionHeading IconSize={section.IconSize}>
                    {section.title}
                  </DetailSectionHeading>
                </>
              ) : (
                <>
                  <NumberContainer> {section.step} </NumberContainer>
                  <DetailSectionHeading IconSize={section.IconSize}>
                    {section.title}
                  </DetailSectionHeading>
                </>
              )
            }
          </Fragment>
        ) : (
          <Fragment key={`detail-section-${section.title}`}>
            <DetailSectionImageContainer IconSize={section.IconSize}>
              {
                section.noHeight ? (
                  <DetailSectionImageNoHeight src={section.image} alt={section.alt} titleText={section.title} />
                ) : (
                  <DetailSectionImage IconSize={section.IconSize} src={section.image} alt={section.alt} titleText={section.title} />
                )
              }
            </DetailSectionImageContainer>
            <DetailSectionHeading IconSize={section.IconSize}>
              {firstLine}
              <br />
              {secondLine}
            </DetailSectionHeading>
          </Fragment>
        )}
      </DetailSectionContainer>
    );
  });
};

const DetailsHeader = styled.h3`
  ${(props) => (props.titleFont ? `font-family: ${props.titleFont};` : 'font-family: Objectivity;')};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '20px;')};
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: ${(props) => (props.color ? props.color : '#000000')};
  max-width: 1200px;
  font-weight: ${(props) => (props.titleFont === 'Roslindale' && `300;`)};
  @media (max-width: 1025px) {
    font-size: 16px;
    line-height: 1.38;
    margin: 0 10px;
    padding: 0;
  }
`

const CopyWidthContainer = styled.div`
  padding-bottom: 0;
`

const FeatureDetails = ({ data }) => {
  return (
    <DetailsContainer backgroundColor={data.backgroundColor}>
      {(data?.Title || data?.extendedTitle) && (
        <CopyWidthContainer>
          {data.SubHeading && (
            <SmallSubHeading color={data.TextColour || "black"}>{data.SubHeading}</SmallSubHeading>
          )}
          <DetailSectionsContaniner mobile2x2={data.mobile2x2}>
            {
              data?.extendedTitle ? (
                <DetailsHeader color={data.TextColour} titleFont={data.TitleFont} subHeading={data.SubHeading} dangerouslySetInnerHTML={{ __html: data.extendedTitle && data.extendedTitle }} />
              ) : (
                <>
                  {data?.Title && (
                    <DetailsHeader color={data.TextColour} titleFont={data.TitleFont} subHeading={data.SubHeading}>{data.Title}</DetailsHeader>
                  )}
                </>
              )
            }
          </DetailSectionsContaniner>
        </CopyWidthContainer>
      )}
      {data.OptionalPNG && (
        <DetailSectionsContaniner style={{ 'padding': 0, 'padding-top': '16px' }}>
          <OptionalImage loading="lazy" src={data.OptionalPNG?.file.publicURL} alt={'optional image'} />
        </DetailSectionsContaniner>
      )
      }
      {
        data.FeatureItems && data.FeatureItems.length > 0 && (
          <DetailSectionsContaninerImages mobile2x2={data.mobile2x2} titleOn={data?.extendedTitle ? data?.extendedTitle : data?.Title}>
            {data.FeatureItems?.map(({ Heading, FeatureImage, key, ItemStep }) => (
              <Fragment key={`feature-details-item-${Heading}`}>
                <DetailSection
                  data={[
                    {
                      image: FeatureImage?.file?.publicURL,
                      title: Heading,
                      stepsOn: data.ShowSteps,
                      step: ItemStep,
                      noHeight: Heading.substring(0, Heading.lastIndexOf(" ")) === 'when you spend',
                      IconSize: data.IconSize,
                      alt: FeatureImage?.alternativeText ? FeatureImage?.alternativeText : FeatureImage?.name,
                    },
                  ]}
                />
              </Fragment>
            ))}
          </DetailSectionsContaninerImages>
  
        )
      }
    </DetailsContainer >
  )
}

export default FeatureDetails
